import {Component, Input, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {TileType} from '../shared/enums/tiletype.enum';
import {ThemeHelper} from '../shared/helpers/theme.helper';

import {Tile} from '../shared/models/tile.model';


@Component({
    selector: 'app-tiles',
    templateUrl: 'tiles.component.html',
    styleUrls: ['tiles.component.css'],
    standalone: false
})

export class TilesComponent implements OnInit {
  @Input() public tiles: any;

  public TileType = TileType;

  constructor(private themeHelper: ThemeHelper,
              private router: Router) {
  }

  public ngOnInit(): void {
    this.tiles.tiles = this.tiles.tiles.sort((a, b) => a.order - b.order);
  }

  public getColor(tile: Tile): string {
    if (this.router.url !== '/processList') {
      // if(type !== TileType.Application && type !== TileType.Product && type !== TileType.Offer)
      //  return 'transparent';
      if (tile.type === TileType.Text) {
        return '#FFFFFF';
      }

      if (this.router.url === '/beyondGases') {
        return '#FFFFFF';
      }

      if (this.themeHelper.customTiles || this.router.url === '/supplyModes') {
        return '#c1c2c3';
      }
    }

    const colorIndex: number = this.tiles.tiles
      .filter(t => t.type === TileType.Application || t.type === TileType.Product || t.type === TileType.Offer)
      .indexOf(tile);

    switch (colorIndex % 4) {
      case 0  :
        return '#1665b2';
      case 1  :
        return '#1973cb';
      case 2  :
        return '#399fcf';
      case 3  :
        return '#4dbcd0';
    }
  }
}
