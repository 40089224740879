<h1 mat-dialog-title>{{ 'add_a_customer' | translate }}</h1>
<form (ngSubmit)="save()" [formGroup]="customerForm">
  <mat-dialog-content class="popup-content">
    <div class="ui-g">
      <div class="ui-g-12">
        <mat-form-field class="add-input">
          <mat-label>{{ 'client_name' | translate }}</mat-label>
          <input
            matInput
            type="text" formControlName="name">
          <mat-hint
            class="hint_errors"
            align="start"
            *ngIf="customerForm.controls.name.errors">
              {{ 'REQUIRED_FIELD' | translate }}
          </mat-hint>
        </mat-form-field>
      </div>
      <div class="ui-g-12">
        <mat-form-field class="add-input">
          <mat-label>{{ 'company_name' | translate }}</mat-label>
          <input
            matInput
            type="text" formControlName="company">
          <mat-hint
            class="hint_errors"
            align="start"
            *ngIf="customerForm.controls.company.errors">
              {{ 'REQUIRED_FIELD' | translate }}
          </mat-hint>
        </mat-form-field>
       </div>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions>
    <div class="dialog_actions">
      <button
        mat-raised-button
        mat-dialog-close
        class="dialog_left_button"
        type="button"
        color="primary">
          {{ 'cancel' | translate }}
      </button>
      <button
        mat-raised-button
        type="submit"
        class="dialog_right_button"
        [disabled]="!customerForm.valid"
        color="primary">
          {{ 'ok' | translate }}
      </button>
    </div>
  </mat-dialog-actions>
</form>
