import {Component, ComponentRef, Input, OnInit} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import {Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {Subscription} from 'rxjs';

/*COMPONENTS*/
import {LoadingComponent} from '../shared/components/loading/loading.component';

/*MODELS*/
import {AnalyticsType} from '../shared/enums/analytics.enum';
import {PageType} from '../shared/enums/pagetype.enum';
import {TileType} from '../shared/enums/tiletype.enum';

/*HELPERS*/
import {NavigationHelper} from '../shared/helpers/navigation.helper';
import {ThemeHelper} from '../shared/helpers/theme.helper';
import {Application} from '../shared/models/application.model';
import {Page} from '../shared/models/page.model';
import {Product} from '../shared/models/product.model';
import {Tile} from '../shared/models/tile.model';
import {AnalyticsService} from '../shared/services/analytics.service';

/*SERVICES*/
import {CatalogService} from '../shared/services/catalog.service';

@Component({
    templateUrl: 'gas.component.html',
    styleUrls: ['gas.component.css'],
    standalone: false
})

export class GasComponent implements OnInit {
  set filter(s: string) {
    this.filterString = s;
  }

  @Input() public ownRef: ComponentRef<GasComponent>;
  @Input() public appComponent: any;
  @Input() public parentPageTitle: string;
  @Input() public fromInfography: boolean;

  public isSearch                          = false;
  public product: Product                  = null;
  public isDetail                          = false;
  public products: any[]                   = [];
  public filterString                      = '';
  private loadingRef: any                  = null;
  private readonly routerSub: Subscription = null;

  public constructor(public translate: TranslateService,
                     public themeHelper: ThemeHelper,
                     private catalogService: CatalogService,
                     private snackBar: MatSnackBar,
                     private router: Router,
                     private navigationHelper: NavigationHelper,
                     private analyticsService: AnalyticsService,
                     private dialog: MatDialog) {

    this.navigationHelper.initGasComponent()
      .subscribe(_ => {
        this.appComponent.gasShown = false;
        this.themeHelper.setPageTitle(this.parentPageTitle);
        if (this.routerSub) {
          this.routerSub.unsubscribe();
        }
        this.ownRef.destroy();
      });

    this.routerSub = router.events.subscribe(_ => {
      this.appComponent.gasShown = false;
      this.themeHelper.setPageTitle(this.parentPageTitle);
      this.navigationHelper.gasComponentObserver = null;
      this.routerSub.unsubscribe();
      this.ownRef.destroy();
    });

    this.analyticsService.sendEvent(AnalyticsType.OPEN_VIEW, '/gas');

    this.showLoadingDialog(true);

    setTimeout(
      () => {
        this.catalogService.fetchCurrentData()
          .then(data => {
            // Focus
            const productMap: any = {};
            if (data.focuses) {
              data.focuses
                .filter(itm => itm.type === 'product')
                .forEach(focus => {
                  if (!productMap[focus.targetId]) {
                    productMap[focus.targetId] = new Set();
                  }

                  if (focus.supplyModes) {
                    focus.supplyModes.forEach(sId => productMap[focus.targetId].add(sId));
                  }
                });
            }

            // Application
            if (data.applications) {
              Object.keys(data.applications)
                .map(key => data.applications[key])
                .forEach((app: Application) => {
                  if (!app.pages) {
                    return;
                  }

                  Object.keys(app.pages)
                    .map(key => app.pages[key])
                    .filter(page => page.type === PageType.Product || page.type === PageType.Offer || page.type === PageType.Tiles)
                    .forEach((page: Page) => {
                      if (page.type === PageType.Product) {
                        if (!productMap[page.productData.id]) {
                          productMap[page.productData.id] = new Set();
                        }

                        if (page.productData.supplyModes) {
                          page.productData.supplyModes.forEach(sId => productMap[page.productData.id].add(sId));
                        }
                      } else if (page.type === PageType.Offer) {
                        page.offer.defaultDetail.products.forEach(product => {
                          if (!productMap[product.id]) {
                            productMap[product.id] = new Set();
                          }

                          if (product.supplyModes) {
                            product.supplyModes.forEach(sId => productMap[product.id].add(sId));
                          }
                        });
                        page.offer.details.forEach(detail => {
                          detail.products.forEach(product => {
                            if (!productMap[product.id]) {
                              productMap[product.id] = new Set();
                            }

                            if (product.supplyModes) {
                              product.supplyModes.forEach(sId => productMap[product.id].add(sId));
                            }
                          });
                        });
                      } else if (page.type === PageType.Tiles) {
                        const tilesHandler = (tiles: Tile[]) => {
                          tiles.forEach(tile => {
                            if (tile.type === TileType.Brand) {
                              tilesHandler(tile.tileBrand.tiles);
                            } else if (tile.type === TileType.Product) {
                              if (!productMap[tile.tileProduct.id]) {
                                productMap[tile.tileProduct.id] = new Set();
                              }

                              if (tile.tileProduct.supplyModes) {
                                tile.tileProduct.supplyModes.forEach(sId => productMap[tile.tileProduct.id].add(sId));
                              }
                            }
                          });
                        };
                        tilesHandler(page.tiles.tiles);
                      }
                    });
                });
            }

            if (data.products === undefined) {
              return;
            }

            Object.keys(productMap).forEach(key => {
              const product = data.products[key];

              if (!product) {
                return;
              }

              this.products.push({
                title      : product.title,
                product,
                supplyModes: Array.from(productMap[key]).filter(itm => itm)
              });
            });
            this.products.sort((a, b) => a.product.title.localeCompare(b.product.title));
            this.showLoadingDialog(false);
          })
          .catch((err) => {
            this.showLoadingDialog(false);
            this.snackBar.open('GasComponent : constructor -> ' + err, 'x',
              {
                duration: 5000
              });
            throw new Error(err);
          });
      }, 500);
  }

  public openProduct(productObj: any): void {
    this.catalogService.initProduct(productObj.product.id, productObj.supplyModes,
      (processedData) => {
        this.navigationHelper.initGasDetailComponent()
          .subscribe(_ => {
            this.isDetail                = false;
            this.product                 = null;
            this.appComponent.showSearch = true;
          });
        this.navigationHelper.routerData = processedData;
        this.product                     = processedData;
        this.isDetail                    = true;
        this.appComponent.search         = false;
        this.appComponent.showSearch     = false;
      });
  }

  public ngOnInit(): void {
    this.themeHelper.setPageTitle(this.translate.instant('premium_gas'));
    this.appComponent.showSearch = true;
  }

  private showLoadingDialog(open: boolean): void {
    if (!open && this.loadingRef !== null) {
      this.loadingRef.close();
      this.loadingRef = null;
      return;
    }

    this.loadingRef = this.dialog.open(LoadingComponent, {disableClose: true});
  }
}
