import {Component} from '@angular/core';
import {OnInit} from '@angular/core';
import {Input} from '@angular/core';
import {Router} from '@angular/router';
import {DomSanitizer} from '@angular/platform-browser';
import {SafeHtml} from '@angular/platform-browser';


/*SERVICES*/
import {AnalyticsService} from '../../shared/services/analytics.service';
import {CatalogService} from '../../shared/services/catalog.service';

/*HELPERS*/
import {ThemeHelper} from '../../shared/helpers/theme.helper';
import {StorageHelper} from '../../shared/helpers/storage.helper';
import {NavigationHelper} from '../../shared/helpers/navigation.helper';

/*COMPONENTS*/
import {AppComponent} from '../../app.component';

@Component({
    selector: 'app-tile-offer',
    templateUrl: 'tile-offer.component.html',
    styleUrls: [
        'tile-offer.component.css'
    ],
    standalone: false
})

export class TileOfferComponent implements OnInit {
  @Input() public data: any;
  @Input() public background: string;

  public content: SafeHtml;

  constructor(private themeHelper: ThemeHelper,
              private analyticsService: AnalyticsService,
              private navigationHelper: NavigationHelper,
              private storageHelper: StorageHelper,
              private router: Router,
              private catalogService: CatalogService,
              public  parent: AppComponent,
              public sanitizer: DomSanitizer) {
  }

  public ngOnInit(): void {
    this.content = this.sanitizer.bypassSecurityTrustHtml(this.data.text);
  }

  public open(): void {
    this.catalogService.initSupplyMode(this.data.id,
      (processedData) => {
        this.navigationHelper.routerData = processedData;

        if (this.router.url.indexOf('infography') !== -1) {
          this.parent.openDetail(1, processedData, true);
          return;
        }
        this.parent.openDetail(1, processedData);
      });
  }
}
