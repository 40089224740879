<h1 mat-dialog-title>{{ 'APPLY_DIALOG_TITLE' | translate }}</h1>
<form (ngSubmit)="apply()" [formGroup]="newAccountForm">
    <mat-dialog-content class="popup-content">
        <div class="ui-g">
            <div class="ui-g-6">
                <mat-form-field class="add-input">
                    <mat-label>{{ 'APPLY_DIALOG_FIRSTNAME' | translate }}</mat-label>
                    <input matInput type="text" formControlName="firstname">
                </mat-form-field>
            </div>
            <div class="ui-g-6">
                <mat-form-field class="add-input">
                    <mat-label>{{ 'APPLY_DIALOG_LASTNAME' | translate }}</mat-label>
                    <input matInput type="text"
                        formControlName="lastname">
                    <mat-hint class="hint_errors" align="start" *ngIf="newAccountForm.controls.lastname.errors">
                        {{ 'REQUIRED_FIELD' | translate }}
                    </mat-hint>
                </mat-form-field>
            </div>

            <div class="ui-g-8">
                <mat-form-field class="add-input">
                    <mat-label>{{ 'APPLY_DIALOG_EMAIL' | translate }}</mat-label>
                    <input matInput type="email" formControlName="email">
                    <mat-hint class="hint_errors" align="start" *ngIf="newAccountForm.controls.email.errors">
                        {{ 'REQUIRED_FIELD' | translate }}
                    </mat-hint>
                </mat-form-field>
            </div>
        
            <div class="ui-g-8">
                <mat-checkbox color="primary" class="admin_checkbox"
                    formControlName="isAdminAccount">
                    {{ 'APPLY_DIALOG_ADMIN' | translate }}
                </mat-checkbox>
            </div>

            <div class="ui-g-12">
                <mat-form-field class="add-input">
                    <mat-label>{{ 'APPLY_DIALOG_REASON' | translate }}</mat-label>
                    <textarea matInput rows="4" formControlName="reason" maxlength="500"></textarea>
                    <mat-hint class="hint_errors" align="start" *ngIf="newAccountForm.controls.reason.errors">
                        {{ 'REQUIRED_FIELD' | translate }}
                    </mat-hint>
                </mat-form-field>
            </div>
        </div>
    </mat-dialog-content>
    <mat-dialog-actions>
        <div class="dialog_actions">
            <button mat-raised-button [mat-dialog-close]="false" class="dialog_left_button" type="button"
                color="primary">
                {{ 'cancel' | translate }}
            </button>
            <button mat-raised-button type="submit" class="dialog_right_button" [disabled]="!newAccountForm.valid"
                color="primary">
                {{ 'VALIDATE' | translate }}
            </button>
        </div>
    </mat-dialog-actions>
</form>