import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';

/*MODELS*/
import {Media} from '../../shared/models/media.model';

@Component({
    templateUrl: 'remove-media.component.html',
    styleUrls: ['remove-media.component.css'],
    standalone: false
})

export class RemoveMediaComponent {
  private readonly media: Media = new Media();

  constructor(public dialogRef: MatDialogRef<RemoveMediaComponent>,
              public snackBar: MatSnackBar,
              @Inject(MAT_DIALOG_DATA) public data: any) {
    if (data !== null) {
      this.media = data;
    }
  }

  public save(): void {
    this.dialogRef.close(this.media);
  }
}
