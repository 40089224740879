import {AfterViewInit, Component, Input, OnChanges, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';

/*COMPONENTS*/
import {AppComponent} from '../app.component';
import {CarouselComponent} from '../shared/components/carousel/carousel.component';
import {AnalyticsType} from '../shared/enums/analytics.enum';

/*ENUMS*/
import {PageType} from '../shared/enums/pagetype.enum';
import {NavigationHelper} from '../shared/helpers/navigation.helper';

/*HELPERS*/
import {ThemeHelper} from '../shared/helpers/theme.helper';

/*MODELS*/
import {Page} from '../shared/models/page.model';
import {AnalyticsService} from '../shared/services/analytics.service';


/*SERVICES*/
import {CatalogService} from '../shared/services/catalog.service';

@Component({
    selector: 'app-process',
    templateUrl: 'process.component.html',
    styleUrls: ['process.component.css'],
    standalone: false
})

export class ProcessComponent implements OnChanges, AfterViewInit {
  @ViewChild(CarouselComponent) carouselComponent: CarouselComponent;
  @Input() application;

  public pages: Page[] = [];
  public PageType: any = PageType;
  private isInit       = false;

  constructor(public parent: AppComponent,
              private route: ActivatedRoute,
              private themeHelper: ThemeHelper,
              private catalogService: CatalogService,
              private translate: TranslateService,
              private router: Router,
              private navigationHelper: NavigationHelper,
              private analyticsService: AnalyticsService) {
    this.parent.activeMenu();

    const data: any = this.navigationHelper.routerData;

    if (data === undefined || data === null) {
      return;
    }

    this.application = data.application;
    this.pages       = this.application.pages;

    this.navigationHelper.routerData = null;
  }

  public onCarouselIndexChanged(index: number): void {
    this.navigationHelper.carouselIndex = index;
  }

  public ngAfterViewInit(): void {
    if (this.navigationHelper.carouselIndex === undefined || this.navigationHelper.carouselIndex === null) {
      return;
    }
    this.carouselComponent.setIndex(this.navigationHelper.carouselIndex);
  }

  public ngOnChanges(): void {
    const current = !!this.application.isMerged;

    this.catalogService.initProcess(this.application.id, !current,
      (processedData) => {
        this.application = processedData.application;
        this.pages       = this.application.pages;

        if (this.isInit) {
          this.analyticsService.sendEvent(AnalyticsType.OPEN_PROCESS, this.application.name);
        }

        this.isInit = true;
      });
  }
}
