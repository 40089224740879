export class UserRequest {
  FirstName: string;
  LastName: string;
  Email: string;
  IsAdmin: boolean;
  Instance: string;
  Reason: string;

  constructor() {
  }
}
