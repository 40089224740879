import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'andFilter',
    standalone: false
})
export class AndFilterPipe implements PipeTransform {
  transform(items: any, filter: any): any {
    if (filter && Array.isArray(items)) {
      const filterKeys = Object.keys(filter);
      return items.filter(item =>
        filterKeys.reduce((memo, keyName) => {
          const filterArray: string[] = filter[keyName].split(' ');
          return (memo && filterArray.every((key) => new RegExp(key, 'gi').test(item[keyName]))
            || filter[keyName] === '');
        }, true));
    } else {
      return items;
    }
  }
}
