import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';

import {SynchronizeService} from '../../shared/services/synchronize.service';

@Component({
    templateUrl: 'select-lang.component.html',
    styleUrls: ['select-lang.component.css'],
    standalone: false
})

export class SelectLangDialogComponent {
  public currentLang: any = null;
  public languages: any[] = [];

  constructor(public  dialogRef: MatDialogRef<SelectLangDialogComponent>,
              public  syncService: SynchronizeService,
              public  snackBar: MatSnackBar,
              @Inject(MAT_DIALOG_DATA) public data: any) {
    this.loadParams();
  }

  public saveLang(): void {
    this.dialogRef.close(this.currentLang);
  }

  private loadParams(): void {
    const params = this.data;

    if (params === null) {
      return;
    }

    this.languages = params.languages;
  }
}
