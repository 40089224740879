import {Component } from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import {ActivatedRoute, Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {environment} from '../../environments/environment';
import {AppComponent} from '../app.component';
import {LoadingComponent} from '../shared/components/loading/loading.component';
import {AnalyticsType} from '../shared/enums/analytics.enum';
import {ThemeHelper} from '../shared/helpers/theme.helper';
import {UserHelper} from '../shared/helpers/user.helper';
import {AnalyticsService} from '../shared/services/analytics.service';
import {AuthService} from '../shared/services/auth.service';
import {SynchronizeService} from '../shared/services/synchronize.service';
import {LoginDialogComponent} from './login-dialog/login-dialog.component';
import { ApplyNewAccountDialogComponent } from './apply-new-account-dialog/apply-new-account-dialog.component';

declare const google: any;

@Component({
    templateUrl: 'login.component.html',
    styleUrls: ['login.component.css'],
    standalone: false
})

export class LoginComponent {

  public appVersion: string;
  public loadingRef: any = null;

  constructor(public  parent: AppComponent,
              public  authService: AuthService,
              public  userHelper: UserHelper,
              public  router: Router,
              public  snackBar: MatSnackBar,
              public  dialog: MatDialog,
              public  syncService: SynchronizeService,
              private translate: TranslateService,
              private route: ActivatedRoute,
              private analyticsService: AnalyticsService,
              private themeHelper: ThemeHelper) {
    this.parent.hideMenu();
    this.parent.hideDetail();

    this.analyticsService.sendEvent(AnalyticsType.OPEN_VIEW, this.router.url);

    this.themeHelper.setPageTitle('');

    this.appVersion = environment.version;
  }

  /*NOTE: Standard Login/Password authentication*/
  public login(): void {
    const dialogRef = this.dialog.open(LoginDialogComponent);
    dialogRef.afterClosed()
      .subscribe(result => {
        if (result === undefined || result === null) // Cancel task
        {
          this.displayLoginFailed();
          return;
        }
        if (result === false) // Cancel task
        {
          return;
        }
        this.showLoadingDialog(true);
        setTimeout(() => {
          this.authService.stdServerAuth(result.username, result.password)
            .subscribe(user => {
                this.showLoadingDialog(false);
                this.onSuccessLogin(user);
              },
              _ => {
                this.showLoadingDialog(false);
                this.displayLoginFailed();
              });
        }, 1000);
      });
  }

  public applyNewAccount(): void {
    const dialogRef = this.dialog.open(ApplyNewAccountDialogComponent, {
        width: '50vw'
      }
    );
  }

  public grantOffline(): void {
    google.accounts.id.initialize({
      client_id: environment.googleClientId,
      callback: (response: any) => this.handleCredentialResponse(response),
      use_fedcm_for_prompt: true,
      ux_mode: 'popup',
      context: 'signin'
    });

    this.showLoadingDialog(true);

    google.accounts.id.prompt(); // Show the Google Sign-In prompt
  }

  private handleCredentialResponse(response: any): void {
    if (response.credential === undefined) {
      this.showLoadingDialog(false);
      this.displayLoginFailed();
      return;
    }
    
    const idToken = response.credential;

    this.authService
      .googleServerAuth(idToken)
      .toPromise()
      .then((user) => {
        this.showLoadingDialog(false);
        this.onSuccessLogin(user);
      })
      .catch((m) => {
        this.showLoadingDialog(false);
        this.displayLoginFailed();
        console.log(m.error);
      });
  }

  private showLoadingDialog(open: boolean): void {
    if (!open && this.loadingRef !== null) {
      this.loadingRef.close();
      this.loadingRef = null;
      return;
    }

    this.loadingRef = this.dialog.open(LoadingComponent, {disableClose: true});
  }

  private onSuccessLogin(user: any): void {
    this.userHelper.saveUser(user);

    this.snackBar.open(this.translate.instant('LOGIN_SUCCESS') + ' ' + user.Email, 'x',
      {
        duration: 5000
      });

    const isSync: boolean = this.syncService.getLastUpdateDate() !== 'none';

    const returnUrl = this.route.snapshot.queryParams['returnUrl'] || null;
    if (returnUrl !== null) {
      this.router.navigate([returnUrl])
        .catch(console.error);
      return;
    }

    if (!isSync) {
      this.router.navigate(['/synchronize'])
        .catch(console.error);
      return;
    }

    this.router.navigate(['/home'])
      .catch(console.error);
  }

  private displayLoginFailed(): void {
    this.snackBar.open(this.translate.instant('signin_failed'), 'x',
      {
        duration: 5000
      });
  }
}
