import {AfterViewInit, Component, Input, OnInit} from '@angular/core';
import {MatSnackBar} from '@angular/material/snack-bar';
import {DomSanitizer} from '@angular/platform-browser';
import {ActivatedRoute, Router} from '@angular/router';

import {TranslateService} from '@ngx-translate/core';

/*COMPONENTS*/
import {AppComponent} from '../app.component';
import {AnalyticsType} from '../shared/enums/analytics.enum';
import {MediaHelper} from '../shared/helpers/media.helper';
import {MimeHelper} from '../shared/helpers/mime.helper';
import {NavigationHelper} from '../shared/helpers/navigation.helper';

/*HELPERS*/
import {ThemeHelper} from '../shared/helpers/theme.helper';
import {Customer} from '../shared/models/customer.model';
import {Media} from '../shared/models/media.model';

/*MODELS*/
import {Service} from '../shared/models/service.model';
import {AnalyticsService} from '../shared/services/analytics.service';

/*SERVICES*/
import {CatalogService} from '../shared/services/catalog.service';
import {CustomerService} from '../shared/services/customer.service';

@Component({
    selector: 'app-service',
    templateUrl: 'service.component.html',
    styleUrls: [
        'service.component.css'
    ],
    standalone: false
})

export class ServiceComponent implements OnInit, AfterViewInit {
  @Input() serviceData;

  public service: Service;
  public documentsIds: any[] = [];
  public selectDocs          = false;

  constructor(public  parent: AppComponent,
              public  mimeHelper: MimeHelper,
              public  mediaHelper: MediaHelper,
              public  sanitizer: DomSanitizer,
              private route: ActivatedRoute,
              private catalogService: CatalogService,
              private analyticsService: AnalyticsService,
              private customerService: CustomerService,
              private router: Router,
              private snackBar: MatSnackBar,
              private themeHelper: ThemeHelper,
              private translate: TranslateService,
              private navigationHelper: NavigationHelper) {
    this.parent.activeMenu();

    const data: any = this.navigationHelper.routerData;

    if (data === undefined || data === null) {
      return;
    }

    this.service = data.service;

    this.navigationHelper.routerData = null;
  }

  private static processMedia(customer: Customer, doc: any): void {
    const media = new Media();

    media.Id   = doc.id.toString();
    media.Name = doc.displayName;
    media.Code = doc.path;
    media.Url  = doc.serverUrl;

    const exist = customer.Medias.find(m => m.Id === doc.id);

    if (exist !== undefined) {
      return;
    }
    customer.Medias.push(media);
  }

  public ngOnInit(): void {
    if (this.service !== null) {
      return;
    }

    if (this.serviceData === undefined) {
      this.goToHome();
      return;
    }

    this.catalogService.initService(this.serviceData.id,
      (processedData) => {
        this.service = processedData.service;
      });
  }

  public ngAfterViewInit(): void {
    this.analyticsService.sendEvent(AnalyticsType.OPEN_BEYOND_GASES, this.service.name);
  }

  public selectItem(event: any, doc: any): void {
    if (!event.checked) {
      this.documentsIds = this.documentsIds.filter(d => d.id !== doc.id);
      return;
    }

    this.documentsIds.push(doc);
  }

  public addToCart(): void {
    const selCustomer = this.customerService.getSelectedCustomer();

    if (selCustomer === null || selCustomer === undefined) {
      this.snackBar.open(this.translate.instant('PLS_SEL_BEFORE'), 'x',
        {
          duration: 5000
        });
      this.selectDocs = false;
      return;
    }

    if (this.service.documents !== undefined) {
      for (const doc of this.service.documents) {
        const exist = this.documentsIds.find(d => d.id === doc.id);

        if (exist === undefined) {
          continue;
        }

        ServiceComponent.processMedia(selCustomer, doc);

        this.analyticsService.sendEvent(AnalyticsType.ADD_TO_CART, doc.fileName);
      }
    }

    this.selectDocs = false;
    this.customerService.setSelectedCustomer(selCustomer);

    if (this.documentsIds.length > 0) {
      this.snackBar.open(this.translate.instant('added_to_cart'), 'x',
        {
          duration: 5000
        });
      this.documentsIds = [];
    }
  }

  public isSelected(doc): boolean {
    const selCustomer = this.customerService.getSelectedCustomer();

    if (selCustomer === undefined || selCustomer === null) {
      return false;
    }

    return selCustomer.Medias.some(item => item.Code === doc.path);
  }

  public activeSelection(): void {
    this.selectDocs = true;
  }

  private goToHome(): void {
    this.parent.goToHome();
  }
}
