import {Component, Inject, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';

/*MODELS*/
import {Customer} from '../../shared/models/customer.model';

@Component({
    templateUrl: 'add-customer.component.html',
    styleUrls: ['add-customer.component.css'],
    standalone: false
})

export class AddCustomerComponent implements OnInit {
  public customerForm: UntypedFormGroup      = null;
  private readonly customer: Customer = new Customer();

  constructor(public  dialogRef: MatDialogRef<AddCustomerComponent>,
              public  snackBar: MatSnackBar,
              private formBuilder: UntypedFormBuilder,
              @Inject(MAT_DIALOG_DATA) public data: any) {
    if (data !== null) {
      this.customer = data;
    }
  }

  public ngOnInit(): void {
    this.customerForm = this.formBuilder.group(
      {
        name   : new UntypedFormControl(
          {
            value   : this.customer.Name,
            disabled: false
          }, [Validators.required]),
        company: new UntypedFormControl(
          {
            value   : this.customer.CompanyName,
            disabled: false
          }, [Validators.required])
      });
  }

  public save(): void {
    this.commitValues();
    this.dialogRef.close(this.customer);
  }

  private commitValues(): void {
    const controls            = this.customerForm.controls;
    this.customer.Name        = controls.name.value;
    this.customer.CompanyName = controls.company.value;
  }
}
