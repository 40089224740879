import {Component} from '@angular/core';
import {OnInit} from '@angular/core';
import {Input} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';
import {SafeHtml} from '@angular/platform-browser';


/*MODEL*/
import {TileType} from '../../shared/enums/tiletype.enum';

@Component({
    selector: 'app-tile-text',
    templateUrl: 'tile-text.component.html',
    styleUrls: [
        'tile-text.component.css'
    ],
    standalone: false
})

export class TileTextComponent implements OnInit {
  @Input() public data: any;
  @Input() public background: string;

  public content: SafeHtml;

  constructor(public sanitizer: DomSanitizer) {
  }

  public ngOnInit(): void {
    if (this.data.text)
      this.content = this.sanitizer.bypassSecurityTrustHtml(this.data.text);
  }

  public getJustification(): string {
    return this.data.tileType === TileType.Text ? 'left' : 'center';
  }

  public getTextAlignment(): string {
    return this.data.tileType === TileType.Text ? 'left' : 'justify';
  }
}
