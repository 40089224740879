import {Component} from '@angular/core';
import {Input} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';

/*SERVICES*/
import {AnalyticsService} from '../../shared/services/analytics.service';

/*HELPERS*/
import {ThemeHelper} from '../../shared/helpers/theme.helper';
import {StorageHelper} from '../../shared/helpers/storage.helper';
import {MediaHelper} from '../../shared/helpers/media.helper';

@Component({
    selector: 'app-tile-video',
    templateUrl: 'tile-video.component.html',
    styleUrls: [
        'tile-video.component.css'
    ],
    standalone: false
})

export class TileVideoComponent {
  @Input() public data: any;
  @Input() public background: string;

  constructor(private themeHelper: ThemeHelper,
              private analyticsService: AnalyticsService,
              private storageHelper: StorageHelper,
              private mediaHelper: MediaHelper,
              public sanitizer: DomSanitizer) {
  }


  public openMedia(mediaPath: string): void {
    this.mediaHelper.openMedia(mediaPath);
  }
}
