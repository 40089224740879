<mat-toolbar
  *ngIf="showMenu"
  class="top_menu_white"
  [ngClass]="{
              'top_menu_grey': checkTheme() === 2,
              'top_menu_blue': checkTheme() === 1,
              'top_menu_white': checkTheme() === 0
             }">
  <button
    mat-icon-button
    (click)="activeSearch()"
    *ngIf="checkTheme() === 2"
    class="back_search">
    <img src="./assets/icons/ic_search_back.png" alt="back"/>
  </button>
  <mat-form-field
    [ngClass]="{'input_search_container_active': checkTheme() === 2,
                  'input_search_container_inactive': checkTheme() !== 2}">
    <button
      mat-icon-button
      class="input_search">
      <mat-icon>search</mat-icon>
    </button>
    <label for="focus-global"></label><input #filter matInput id="focus-global">
  </mat-form-field>
  <div class="button_menu_container" *ngIf="!showLogo() && checkTheme() !== 2">
    <button mat-button class="button_menu_left" (click)="goToHome()">
      <img src="./assets/icons/ic_home.png" class="button_menu_left_img" alt="home"/>
    </button>
    <button
      mat-button
      *ngIf="syncService.getLastUpdateDate() !== 'none'"
      class="button_menu_right"
      (click)="navigationHelper.back()">
      <img src="./assets/icons/ic_back.png" class="button_menu_right_img" alt="back"/>
    </button>
    <button
      mat-icon-button
      (click)="activeSearch()"
      *ngIf="showSearch"
      [ngClass]="{'button_menu_search_blue': checkTheme() === 0,
                    'button_menu_search_white': checkTheme() === 1}">
      <mat-icon>search</mat-icon>
    </button>
  </div>
  <img
    class="menu_top_image"
    src="./assets/al_logo.png"
    *ngIf="showLogo()"
    (click)="clickOnLogo()" alt="logo"/>
  <app-top-menu></app-top-menu>
  <div class="app_toolbar_spacer">
    <h1
      [ngClass]="{'app_title_blue': checkTheme() === 0, 'app_title_white': checkTheme() === 1}"
      *ngIf="!showLogo() && checkTheme() !== 2">
      {{ themeHelper.pageTitle }}
    </h1>
  </div>
  <div class="transparent_wifi_bg" *ngIf="checkTheme() !== 2">
    <button
      [ngClass]="{'internet_button': checkTheme() === 0, 'internet_button_white': checkTheme() === 1}"
      class="internet_button"
      mat-icon-button
      *ngIf="!(analyticsService.isOnline)">
      <mat-icon>signal_wifi_off</mat-icon>
    </button>
  </div>
  <div [ngClass]="{'transparent_gas_bg': !gas, 'blue_gas_bg': gas}" *ngIf="checkTheme() !== 2">
    <button class="menu_button" mat-icon-button (click)="goToGas()">
      <mat-icon>
        <img
          src="./assets/icons/ic_gas_blue.png"
          *ngIf="checkTheme() === 0 && !gas"
          alt="gas">
        <img
          src="./assets/icons/ic_gas_white.png"
          *ngIf="checkTheme() === 1 || gas"
          alt="gas">
      </mat-icon>
    </button>
  </div>
  <div [ngClass]="{'transparent_media_bg': !media, 'blue_bg': media}" *ngIf="checkTheme() !== 2">
    <button class="menu_button" mat-icon-button (click)="goToMedia()">
      <mat-icon>
        <img
          src="./assets/icons/ic_media_blue.png"
          *ngIf="checkTheme() === 0 && !media"
          alt="media">
        <img
          src="./assets/icons/ic_media_white.png"
          *ngIf="checkTheme() === 1 || media"
          alt="media">
      </mat-icon>
    </button>
  </div>
  <div [ngClass]="{'transparent_cart_bg': customer == 0,
                     'white_bg': customer == 1,
                     'blue_cust_bg': customer === 2 }"
       *ngIf="checkTheme() !== 2">
    <button class="menu_button" mat-icon-button (click)="goToCustomer()">
      <mat-icon>
        <img
          src="./assets/icons/ic_cart_blue.png"
          *ngIf="checkTheme() === 0 && customer !== 2 || customer === 1"
          alt="cart">
        <img
          src="./assets/icons/ic_cart_white.png"
          *ngIf="checkTheme() === 1 && customer === 0"
          alt="cart">
        <img
          src="./assets/icons/ic_cart_white.png"
          *ngIf="checkTheme() === 0 && customer === 2"
          alt="cart">
      </mat-icon>
    </button>
  </div>
  <div style="position: relative;">
    <button
      class="more_button"
      mat-icon-button
      *ngIf="checkTheme() !== 2"
      [matMenuTriggerFor]="menu"
      (click)="sidenav.close();">
      <mat-icon>more_vert</mat-icon>
    </button>
    <div class="badge" *ngIf="syncService.update && checkTheme() !== 2">
    </div>
  </div>
  <mat-menu #menu="matMenu">
    <app-extras-menu></app-extras-menu>
  </mat-menu>
</mat-toolbar>
<mat-sidenav-container class="app_container">
  <mat-sidenav #sidenav mode="over" class="app_sidenav">
    <app-side-menu></app-side-menu>
  </mat-sidenav>
  <div class="app_sidenav-content">
    <router-outlet></router-outlet>
    <app-process-detail
      *ngIf="showDetail"
      [pageType]="pageType"
      [data]="data"
      [height]="height"
      [width]="width"
      [parent]="appComponent">
    </app-process-detail>
  </div>
</mat-sidenav-container>
