import {Component, Input} from '@angular/core';

/*SERVICES*/
/*COMPONENTS*/
import {Application} from '../../shared/models/application.model';

@Component({
    selector: 'app-infography-detail',
    templateUrl: 'infography-detail.component.html',
    styleUrls: ['infography-detail.component.css'],
    standalone: false
})

export class InfographyDetailComponent {
  @Input() application: Application;

  public constructor() {
  }
}
