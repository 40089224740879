import {Component} from '@angular/core';
import {MatSnackBar} from '@angular/material/snack-bar';
import {Router} from '@angular/router';

import {TranslateService} from '@ngx-translate/core';

/*COMPONENTS*/
import {AppComponent} from '../app.component';

/*ENUMS*/
import {AnalyticsType} from '../shared/enums/analytics.enum';

/*HELPERS*/
import {ThemeHelper} from '../shared/helpers/theme.helper';

/*SERVICES*/
import {AnalyticsService} from '../shared/services/analytics.service';
import {MailService} from '../shared/services/mail.service';

@Component({
    templateUrl: 'signature.component.html',
    styleUrls: ['signature.component.css'],
    standalone: false
})

export class SignatureComponent {
  quillConfig = {
    toolbar: [
      ['bold', 'italic', 'underline'],        // toggled buttons

      [{list: 'ordered'}, {list: 'bullet'}],
      [{script: 'sub'}, {script: 'super'}],      // superscript/subscript

      [{size: ['small', false, 'large', 'huge']}],  // custom dropdown
      [{header: [1, 2, 3, false]}],

      [{color: []}, {background: []}],          // dropdown with defaults from theme
      [{align: []}],

      ['clean'],                                         // remove formatting button

      ['link', 'image']                         // link and image, video
    ]
  };


  htmlSignature = '';

  constructor(private translate: TranslateService,
              private themeHelper: ThemeHelper,
              private parent: AppComponent,
              private mailService: MailService,
              private snackBar: MatSnackBar,
              private router: Router,
              private analytics: AnalyticsService) {
    this.parent.activeMenu();

    this.themeHelper.setPageTitle(this.translate.instant('SIGNATURE'));

    this.analytics.sendEvent(AnalyticsType.OPEN_VIEW, this.router.url);

    this.htmlSignature = this.mailService.getSignature();
  }

  public saveSignature(): void {
    this.mailService.saveSignature(this.htmlSignature);

    this.snackBar.open(this.translate.instant('SIGNATURE_SAVED'), 'x',
      {
        duration: 5000
      });
  }
}
