import {Component, ViewEncapsulation} from '@angular/core';


@Component({
    selector: 'app-top-menu',
    templateUrl: 'top-menu.component.html',
    styleUrls: ['top-menu.component.css'],
    encapsulation: ViewEncapsulation.None,
    standalone: false
})

export class TopMenuComponent {
  constructor() {
  }

}
