// Angular modules
import { CdkTableModule }             from '@angular/cdk/table';
import { HttpClient, provideHttpClient, withInterceptorsFromDi }                 from '@angular/common/http';
import { HttpClientModule }           from '@angular/common/http';
import { NgModule }                   from '@angular/core';
import { FormsModule }                from '@angular/forms';
import { ReactiveFormsModule }        from '@angular/forms';
import { MatButtonModule }            from '@angular/material/button';
import { MatCardModule }              from '@angular/material/card';
import { MatCheckboxModule }          from '@angular/material/checkbox';
import { MatChipsModule }             from '@angular/material/chips';
import { MatDialogModule }            from '@angular/material/dialog';
import { MatExpansionModule }         from '@angular/material/expansion';
import { MatFormFieldModule }         from '@angular/material/form-field';
import { MatGridListModule }          from '@angular/material/grid-list';
import { MatIconModule }              from '@angular/material/icon';
import { MatInputModule }             from '@angular/material/input';
import { MatListModule }              from '@angular/material/list';
import { MatMenuModule }              from '@angular/material/menu';
import { MatProgressBarModule }       from '@angular/material/progress-bar';
import { MatProgressSpinnerModule }   from '@angular/material/progress-spinner';
import { MatRadioModule }             from '@angular/material/radio';
import { MatSelectModule }            from '@angular/material/select';
import { MatSidenavModule }           from '@angular/material/sidenav';
import { MatSlideToggleModule }       from '@angular/material/slide-toggle';
import { MatSnackBarModule }          from '@angular/material/snack-bar';
import { MatSortModule }              from '@angular/material/sort';
import { MatTableModule }             from '@angular/material/table';
import { MatToolbarModule }           from '@angular/material/toolbar';
import { BrowserModule }              from '@angular/platform-browser';
import { BrowserAnimationsModule }    from '@angular/platform-browser/animations';
import { ServiceWorkerModule }        from '@angular/service-worker';

// External modules
import { AndFilterPipe }              from './shared/pipes/andfilter.pipes';
import { FilterPipe }                 from './shared/pipes/filter.pipes';
import { FromStoragePipe }            from './shared/pipes/fromStorage.pipes';
import { SearchPipe }                 from './shared/pipes/search.pipes';
import { TranslateLoader }            from '@ngx-translate/core';
import { TranslateModule }            from '@ngx-translate/core';
import { TranslateHttpLoader }        from '@ngx-translate/http-loader';
import { QuillModule }                from 'ngx-quill';

// Internal modules
import { AppRoutingModule }           from './app-routing.module';
import { environment }                from 'src/environments/environment';

// Directives
import { VscrollDirective }           from './shared/components/vscroll/vscroll.directive';

// Components
import { AppComponent }               from './app.component';
import { BeyondGasesComponent }       from './beyond-gases/beyond-gases.component';
import { BrandComponent }             from './brand/brand.component';
import { AddCustomerComponent }       from './customer/add-customer/add-customer.component';
import { CustomerDetailComponent }    from './customer/customer-detail/customer-detail.component';
import { CustomerComponent }          from './customer/customer.component';
import { RemoveCustomerComponent }    from './customer/remove-customer/remove-customer.component';
import { RemoveExpectationComponent } from './customer/remove-expectation/remove-expectation.component';
import { RemoveMediaComponent }       from './customer/remove-media/remove-media.component';
import { GasComponent }               from './gas/gas.component';
import { HomeComponent }              from './home/home.component';
import { InfographyDetailComponent }  from './infography/infography-detail/infography-detail.component';
import { InfographyZoomComponent }    from './infography/infography-zoom/infography-zoom.component';
import { InfographyComponent }        from './infography/infography.component';
import { LoginDialogComponent }       from './login/login-dialog/login-dialog.component';
import { ApplyNewAccountDialogComponent } from './login/apply-new-account-dialog/apply-new-account-dialog.component';
import { LoginComponent }             from './login/login.component';
import { LogoutComponent }            from './logout/logout.component';
import { MediaDetailComponent }       from './media/media-detail/media-detail.component';
import { MediaComponent }             from './media/media.component';
import { PageNotFoundComponent }      from './not-found/not-found.component';
import { OfferComponent }             from './offer/offer.component';
import { ProcessDetailComponent }     from './process-detail/process-detail.component';
import { AboutComponent }             from './process/about/about.component';
import { ExpectationComponent }       from './process/expectation/expectation.component';
import { ProcessComponent }           from './process/process.component';
import { ProposalComponent }          from './process/proposal/proposal.component';
import { ProcessListComponent }       from './processlist/processlist.component';
import { ProductComponent }           from './product/product.component';
import { ServiceComponent }           from './service/service.component';
import { CarouselComponent }          from './shared/components/carousel/carousel.component';
import { ExtrasMenuComponent }        from './shared/components/extras-menu/extras-menu.component';
import { LoadingComponent }           from './shared/components/loading/loading.component';
import { OverlayComponent }           from './shared/components/overlay/overlay.component';
import { SideMenuComponent }          from './shared/components/side-menu/side-menu.component';
import { TopMenuComponent }           from './shared/components/top-menu/top-menu.component';
import { VideoFullscreenComponent }   from './shared/components/video-fullscreen/video-fullscreen.component';
import { SignatureComponent }         from './signature/signature.component';
import { SupplyModesComponent }       from './supply-modes/supply-modes.component';
import { DownloadDialogComponent }    from './synchronize/download/download.component';
import { SelectLangDialogComponent }  from './synchronize/select-lang/select-lang.component';
import { SynchronizeComponent }       from './synchronize/synchronize.component';
import { TileApplicationComponent }   from './tiles/tile-application/tile-application.component';
import { TileBeyondComponent }        from './tiles/tile-beyond/tile-beyond.component';
import { TileBrandComponent }         from './tiles/tile-brand/tile-brand.component';
import { TileOfferComponent }         from './tiles/tile-offer/tile-offer.component';
import { TilePictureComponent }       from './tiles/tile-picture/tile-picture.component';
import { TileProductComponent }       from './tiles/tile-product/tile-product.component';
import { TileServiceComponent }       from './tiles/tile-service/tile-service.component';
import { TileTextComponent }          from './tiles/tile-text/tile-text.component';
import { TileVideoComponent }         from './tiles/tile-video/tile-video.component';
import { TilesComponent }             from './tiles/tiles.component';
import { WelcomeDetailComponent }     from './welcome/welcome-detail/welcome-detail.component';
import { WelcomeComponent }           from './welcome/welcome.component';

export const createTranslateLoader = (http: HttpClient) => new TranslateHttpLoader(http, './assets/i18n/', '.json');

@NgModule({ declarations: [
        AppComponent,
        BeyondGasesComponent,
        BrandComponent,
        CarouselComponent,
        CustomerComponent,
        AddCustomerComponent,
        CustomerDetailComponent,
        RemoveCustomerComponent,
        RemoveExpectationComponent,
        RemoveMediaComponent,
        ExtrasMenuComponent,
        GasComponent,
        HomeComponent,
        InfographyComponent,
        InfographyZoomComponent,
        InfographyDetailComponent,
        LoadingComponent,
        LoginComponent,
        ApplyNewAccountDialogComponent,
        LoginDialogComponent,
        LogoutComponent,
        MediaComponent,
        MediaDetailComponent,
        PageNotFoundComponent,
        OfferComponent,
        OverlayComponent,
        ProcessComponent,
        AboutComponent,
        ExpectationComponent,
        ProposalComponent,
        ProcessDetailComponent,
        ProcessListComponent,
        ProductComponent,
        ServiceComponent,
        SignatureComponent,
        SideMenuComponent,
        TopMenuComponent,
        SupplyModesComponent,
        SynchronizeComponent,
        SelectLangDialogComponent,
        DownloadDialogComponent,
        TilesComponent,
        TileApplicationComponent,
        TileBeyondComponent,
        TileBrandComponent,
        TileOfferComponent,
        TilePictureComponent,
        TileProductComponent,
        TileServiceComponent,
        TileTextComponent,
        TileVideoComponent,
        VideoFullscreenComponent,
        WelcomeComponent,
        WelcomeDetailComponent,
        AndFilterPipe,
        FromStoragePipe,
        FilterPipe,
        SearchPipe,
        VscrollDirective,
    ],
    bootstrap: [AppComponent], imports: [BrowserModule,
        BrowserAnimationsModule,
        FormsModule,
        ReactiveFormsModule,
        AppRoutingModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: (createTranslateLoader),
                deps: [HttpClient]
            }
        }),
        QuillModule.forRoot(),
        MatDialogModule,
        MatSnackBarModule,
        MatRadioModule,
        MatSortModule,
        MatIconModule,
        MatProgressBarModule,
        MatProgressSpinnerModule,
        MatCardModule,
        MatExpansionModule,
        MatListModule,
        MatCheckboxModule,
        MatChipsModule,
        MatSelectModule,
        MatGridListModule,
        MatButtonModule,
        MatToolbarModule,
        MatInputModule,
        MatMenuModule,
        MatSidenavModule,
        MatTableModule,
        MatFormFieldModule,
        MatSlideToggleModule,
        CdkTableModule,
        ServiceWorkerModule.register('ngsw-worker.js', {
            enabled: environment.serviceWorkerEnabled,
            // Register the ServiceWorker as soon as the application is stable
            // or after 30 seconds (whichever comes first).
            registrationStrategy: 'registerWhenStable:30000'
        })], providers: [provideHttpClient(withInterceptorsFromDi())] })

export class AppModule {
}
