import { HttpClient, HttpHeaders } from '@angular/common/http';
import {Injectable} from '@angular/core';
import {CookieService} from 'ngx-cookie-service';
import {Observable, throwError} from 'rxjs';

import {catchError, map} from 'rxjs/operators';
import {environment} from '../../../environments/environment';

import {User} from '../models/user.model';

@Injectable({
  providedIn: 'root'
})
export class AccountsService {
  currentId                 = '';
  users: User[];
  headers: HttpHeaders      = null;
  options: { headers: any, withCredentials: true } = null;

  constructor(public  http: HttpClient,
              private cookieService: CookieService) {
    this.setHeaders();
  }

  public applyForNewAccount(user: User): Observable<boolean> {
    return this.http.post<boolean>(environment.api + '/User/apply', user, this.options)
      .pipe(
        catchError((error: any) => throwError(error.text().Message || 'Server error'))
      );
  }

  // public getUsers(): Observable<User[]> {
  //   this.setHeaders();

  //   this.users = [];

  //   return this.http.get<any[]>(environment.api + '/User', this.options)
  //     .pipe(
  //       map(
  //         jsonUsers => {
  //           jsonUsers.forEach(
  //             (user: any) => {
  //               this.users.push(User.New(user));
  //             });

  //           return this.users;
  //         }
  //       ),
  //       catchError(error => throwError(error.text().Message || 'Server error'))
  //     );
  // }

  // public getUser(id: string): Promise<User> {
  //   this.currentId = id;
  //   return Promise.resolve(this.users).then(
  //     users => users.find(
  //       user => user.id === id));
  // }

  // public setUser(updatedUser: User): Promise<User> {
  //   return this.getUser(updatedUser.id).then(
  //     user => {
  //       if (user) {
  //         return Object.assign(user, updatedUser);
  //       }
  //       return null;
  //     });
  // }

  // public addUser(user: User, edit: boolean, editPass: boolean): Observable<User> {
  //   let lastIdx = -1;

  //   return this.http.post<any>(environment.api + '/User?edit=' + edit + '&editPass=' + editPass, user, this.options)
  //     .pipe(
  //       map(
  //         jsonUser => {

  //           if (edit) {
  //             return User.New(jsonUser);
  //           }

  //           this.users.push(User.New(jsonUser));

  //           lastIdx = this.users.length - 1;

  //           return this.users[lastIdx];
  //         }
  //       ),
  //       catchError((error: any) => throwError(error.text().Message || 'Server error'))
  //     );
  // }

  // public removeUser(user: User): Observable<User> {
  //   return this.http.post<User>(environment.api + '/User/remove', user, this.options)
  //     .pipe(
  //       map(res => {
  //         this.users.splice(this.users.indexOf(user), 1);
  //         return res;
  //       }),
  //       catchError((error: any) => throwError(error.text().Message || 'Server error'))
  //     );
  // }

  // public getUserByEmail(userEmail: string): User {

  //   const foundedUser: User = this.users.find(
  //     (item) => item.Email === userEmail);
  //   if (foundedUser === undefined) {
  //     return null;
  //   }

  //   return foundedUser;
  // }

  // public findUserById(userId: string): User {
  //   const foundedUser: User = this.users.find(
  //     (item) => item.id === userId);
  //   if (foundedUser === undefined) {
  //     return null;
  //   }

  //   return foundedUser;
  // }

  private setHeaders(): void {
    this.headers = new HttpHeaders(
      {
        'Content-Type': 'application/json',
        Accept        : 'application/json'
      });
    this.options = {headers: this.headers, withCredentials: true};
  }
}
