import {Component} from '@angular/core';
import {Input} from '@angular/core';
import {Router} from '@angular/router';
import {DomSanitizer} from '@angular/platform-browser';

/*SERVICES*/
import {AnalyticsService} from '../../shared/services/analytics.service';
import {CatalogService} from '../../shared/services/catalog.service';

/*HELPERS*/
import {ThemeHelper} from '../../shared/helpers/theme.helper';
import {StorageHelper} from '../../shared/helpers/storage.helper';
import {NavigationHelper} from '../../shared/helpers/navigation.helper';

/*COMPONENTS*/
import {AppComponent} from '../../app.component';

@Component({
    selector: 'app-tile-service',
    templateUrl: 'tile-service.component.html',
    styleUrls: [
        'tile-service.component.css'
    ],
    standalone: false
})

export class TileServiceComponent {
  @Input() public data: any;
  @Input() public background: string;

  constructor(public sanitizer: DomSanitizer,
              public  parent: AppComponent,
              private themeHelper: ThemeHelper,
              private analyticsService: AnalyticsService,
              private storageHelper: StorageHelper,
              private catalogService: CatalogService,
              private navigationHelper: NavigationHelper,
              private router: Router) {
  }

  public open(): void {
    this.catalogService.initService(this.data.id,
      (processedData) => {
        if (this.router.url === '/beyondGases') {
          const currentPath = this.themeHelper.pageTitle;
          this.themeHelper.setPageTitle(currentPath + '/' + processedData.service.name);
        }

        this.navigationHelper.routerData = processedData;

        if (this.router.url.indexOf('infography') !== -1) {
          this.parent.openDetail(3, processedData, true);
          return;
        }

        this.parent.openDetail(3, processedData);
      });
  }
}
