import {Component} from '@angular/core';
import {Input} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';

@Component({
    selector: 'app-tile-picture',
    templateUrl: 'tile-picture.component.html',
    styleUrls: [
        'tile-picture.component.css'
    ],
    standalone: false
})

export class TilePictureComponent {
  @Input() public data: any;
  @Input() public background: string;

  constructor(public sanitizer: DomSanitizer) {
  }
}
