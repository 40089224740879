import {Component, Input} from '@angular/core';
import {MatSnackBar} from '@angular/material/snack-bar';
import {DomSanitizer} from '@angular/platform-browser';

import {TranslateService} from '@ngx-translate/core';
import {AnalyticsType} from '../../shared/enums/analytics.enum';
import {MediaHelper} from '../../shared/helpers/media.helper';
import {MimeHelper} from '../../shared/helpers/mime.helper';
import {StorageHelper} from '../../shared/helpers/storage.helper';

/*HELPERS*/
import {ThemeHelper} from '../../shared/helpers/theme.helper';

/*MODELS*/
import {Customer} from '../../shared/models/customer.model';
import {Media} from '../../shared/models/media.model';

/*SERVICES*/
import {AnalyticsService} from '../../shared/services/analytics.service';
import {CustomerService} from '../../shared/services/customer.service';

@Component({
    selector: 'app-about',
    templateUrl: 'about.component.html',
    styleUrls: ['about.component.css'],
    standalone: false
})

export class AboutComponent {
  @Input() public about: any;

  public selectDocs          = false;
  public documentsIds: any[] = [];

  constructor(private themeHelper: ThemeHelper,
              private analyticsService: AnalyticsService,
              private storageHelper: StorageHelper,
              private customerService: CustomerService,
              private snackBar: MatSnackBar,
              private translate: TranslateService,
              public  sanitizer: DomSanitizer,
              public  mimeHelper: MimeHelper,
              public  mediaHelper: MediaHelper) {
  }

  private static processMedia(customer: Customer, doc: any): void {
    const media = new Media();

    media.Id   = doc.id.toString();
    media.Name = doc.displayName;
    media.Code = doc.path;
    media.Url  = doc.serverUrl;

    const exist = customer.Medias.find(m => m.Id === doc.id);

    if (exist !== undefined) {
      return;
    }


    customer.Medias.push(media);
  }

  public openMedia(mediaPath: string): void {
    this.mediaHelper.openMedia(mediaPath);
  }

  public addToCart(): void {
    const selCustomer = this.customerService.getSelectedCustomer();

    if (selCustomer === null || selCustomer === undefined) {
      this.snackBar.open(this.translate.instant('PLS_SEL_BEFORE'), 'x',
        {
          duration: 5000
        });
      this.selectDocs = false;
      return;
    }

    if (this.about.documents !== undefined) {
      for (const doc of this.about.documents) {
        const exist = this.documentsIds.find(d => d.id === doc.id);

        if (exist === undefined) {
          continue;
        }

        AboutComponent.processMedia(selCustomer, doc);

        this.analyticsService.sendEvent(AnalyticsType.ADD_TO_CART, doc.fileName);
      }
    }

    this.selectDocs = false;
    this.customerService.setSelectedCustomer(selCustomer);

    if (this.documentsIds.length > 0) {
      this.snackBar.open(this.translate.instant('added_to_cart'), 'x',
        {
          duration: 5000
        });
      this.documentsIds = [];
    }
  }

  public isSelected(doc): boolean {

    const selCustomer: Customer = this.customerService.getSelectedCustomer();
    if (selCustomer === undefined || selCustomer === null) {
      return false;
    }

    const foundedItem = selCustomer.Medias
      .find((item) => item.Code === doc.path);
    return foundedItem !== undefined;
  }

  public activeSelection(): void {
    this.selectDocs = true;
  }

  public selectItem(event: any, doc: any): void {
    if (!event.checked) {
      this.documentsIds = this.documentsIds.filter(d => d.id !== doc.id);
      return;
    }

    this.documentsIds.push(doc);
  }
}
