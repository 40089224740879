import {Component, Input, OnInit} from '@angular/core';
import {MatSnackBar} from '@angular/material/snack-bar';

import {TranslateService} from '@ngx-translate/core';
import {AnalyticsType} from '../../shared/enums/analytics.enum';
import {MediaHelper} from '../../shared/helpers/media.helper';
import {MimeHelper} from '../../shared/helpers/mime.helper';
import {ThemeHelper} from '../../shared/helpers/theme.helper';
import {Customer} from '../../shared/models/customer.model';
import {Media} from '../../shared/models/media.model';
import {AnalyticsService} from '../../shared/services/analytics.service';
import {CustomerService} from '../../shared/services/customer.service';


@Component({
    selector: 'app-proposal',
    templateUrl: 'proposal.component.html',
    styleUrls: [
        'proposal.component.css'
    ],
    standalone: false
})

export class ProposalComponent implements OnInit {
  @Input() public proposals: any;

  public selectDocs          = false;
  public documentsIds: any[] = [];

  constructor(
    private themeHelper: ThemeHelper,
    private analyticsService: AnalyticsService,
    private customerService: CustomerService,
    private snackBar: MatSnackBar,
    private translate: TranslateService,
    public  mimeHelper: MimeHelper,
    public  mediaHelper: MediaHelper) {
  }

  private static processMedia(customer: Customer, doc: any): void {
    const media = new Media();

    media.Id   = doc.id.toString();
    media.Name = doc.displayName;
    media.Code = doc.path;
    media.Url  = doc.serverUrl;

    const exist = customer.Medias.find(m => m.Id === doc.id);

    if (exist !== undefined) {
      return;
    }


    customer.Medias.push(media);
  }

  public ngOnInit(): void {
  }

  public getColor(index: number): string {
    switch (index) {
      case 0  :
        return '#1665b2';
      case 1  :
        return '#1973cb';
      case 2  :
        return '#399fcf';
      case 3  :
        return '#4dbcd0';
      case 4  :
        return '#1665b2';
      case 5  :
        return '#1973cb';
      case 6  :
        return '#399fcf';
      case 7  :
        return '#4dbcd0';
      default :
        return '#1665b2';
    }
  }

  public addToCart(): void {
    const selCustomer = this.customerService.getSelectedCustomer();

    if (selCustomer === null || selCustomer === undefined) {
      this.snackBar.open(this.translate.instant('PLS_SEL_BEFORE'), 'x',
        {
          duration: 5000
        });
      this.selectDocs = false;
      return;
    }

    if (this.proposals.documents !== undefined) {
      for (const doc of this.proposals.documents) {
        const exist = this.documentsIds.find(d => d.id === doc.id);

        if (exist === undefined) {
          continue;
        }

        ProposalComponent.processMedia(selCustomer, doc);

        this.analyticsService.sendEvent(AnalyticsType.ADD_TO_CART, doc.fileName);
      }
    }

    this.selectDocs = false;
    this.customerService.setSelectedCustomer(selCustomer);

    if (this.documentsIds.length > 0) {
      this.snackBar.open(this.translate.instant('added_to_cart'), 'x',
        {
          duration: 5000
        });
      this.documentsIds = [];
    }
  }

  public isSelected(doc): boolean {
    const selCustomer = this.customerService.getSelectedCustomer();

    if (selCustomer === undefined || selCustomer === null) {
      return false;
    }

    return selCustomer.Medias.some(item => item.Code === doc.path);
  }

  public activeSelection(): void {
    this.selectDocs = true;
  }

  public selectItem(event: any, doc: any): void {
    if (!event.checked) {
      this.documentsIds = this.documentsIds.filter(d => d.id !== doc.id);
      return;
    }

    this.documentsIds.push(doc);
  }

}
