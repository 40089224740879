import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { LoadingComponent } from 'src/app/shared/components/loading/loading.component';
import { UserRequest } from 'src/app/shared/models/user_request.model';
import { AccountsService } from 'src/app/shared/services/accounts.service';

@Component({
    templateUrl: 'apply-new-account-dialog.component.html',
    styleUrls: ['apply-new-account-dialog.component.css'],
    standalone: false
})

export class ApplyNewAccountDialogComponent implements OnInit {
    public newAccountForm: UntypedFormGroup = null;
    public loadingRef: any = null;
    
    constructor(public dialogRef: MatDialogRef<ApplyNewAccountDialogComponent>,
        public snackBar: MatSnackBar,
        public  dialog: MatDialog,
        private formBuilder: UntypedFormBuilder,
        private accountsService: AccountsService,
        private translate: TranslateService) {
    }

    public ngOnInit(): void {
        this.newAccountForm = this.formBuilder.group(
            {
                lastname: new UntypedFormControl("", [Validators.required]),
                firstname: new UntypedFormControl(),
                email: new UntypedFormControl("", [Validators.required, Validators.email]),
                isAdminAccount: new UntypedFormControl(false),
                reason: new UntypedFormControl("", [Validators.required])
            });
    }

    public apply(): void {
        const user = new UserRequest();
        user.LastName = this.newAccountForm.controls['lastname'].value;
        user.FirstName = this.newAccountForm.controls['firstname'].value;
        user.Email = this.newAccountForm.controls['email'].value;
        user.IsAdmin = this.newAccountForm.controls['isAdminAccount'].value;
        user.Reason = this.newAccountForm.controls['reason'].value;

        this.showLoadingDialog(true);

        this.accountsService.applyForNewAccount(this.newAccountForm.value)
            .subscribe(
                (result) => {
                    this.showLoadingDialog(false);
                    if (result) {
                        this.snackBar.open(this.translate.instant('APPLY_DIALOG_REQUEST_SENT'), 'x', { duration: 5000 });
                        this.dialogRef.close();
                    }
                },
                (error) => {
                    this.showLoadingDialog(false);
                    console.error('Error applying for new account', error);
                    this.snackBar.open(this.translate.instant('ERROR_OCCURRED'), 'x', { duration: 5000 });
                }
            );
    }

    private showLoadingDialog(open: boolean): void {
        if (!open && this.loadingRef !== null) {
          this.loadingRef.close();
          this.loadingRef = null;
          return;
        }
    
        this.loadingRef = this.dialog.open(LoadingComponent, {disableClose: true});
    }

}
