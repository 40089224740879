import { HttpClient, HttpHeaders } from '@angular/common/http';
import {Injectable} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';
import {Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';

import {CookieService} from 'ngx-cookie-service';
import {Observable, throwError} from 'rxjs';
import {catchError, map, timeout} from 'rxjs/operators';
import {environment} from '../../../environments/environment';
import {PageType} from '../enums/pagetype.enum';
import {TileType} from '../enums/tiletype.enum';
import {NavigationHelper} from '../helpers/navigation.helper';
import {StorageHelper} from '../helpers/storage.helper';

import {UserHelper} from '../helpers/user.helper';
import {AppData} from '../models/appdata.model';
import {Application} from '../models/application.model';

import {Country} from '../models/country.model';
import {Language} from '../models/language.model';
import {Page} from '../models/page.model';
import {Tile} from '../models/tile.model';

@Injectable({
  providedIn: 'root'
})
export class CatalogService {
  public static readonly COUNTRIES_KEY    = 'COUNTRIES';
  public static readonly SELLANG_KEY      = 'SELLANG';
  public static readonly CURLANG_KEY      = 'CURLANG';
  public static readonly CURLANGLABEL_KEY = 'CURLANGLABEL';

  private static currentData: AppData;
  private static mergedData: AppData;

  public countries: Country[];
  public isLoggedIn                 = false;
  public connectedMail              = '';
  public defaultInstance: string    = null;
  private headers: HttpHeaders      = null;
  private options: { headers: any, withCredentials: true } = null;
  private _mergedApplications: Application[];

  constructor(public  http: HttpClient,
              private userHelper: UserHelper,
              private cookieService: CookieService,
              private navigationHelper: NavigationHelper,
              private translate: TranslateService,
              private router: Router,
              private sanitizer: DomSanitizer,
              private storageHelper: StorageHelper) {
    this.setHeaders();

    this.defaultInstance = this.getSelectedLanguageLabel();
  }

  public get mergedApplications(): Application[] {
    if (!this._mergedApplications) {
      this.processApplications();
    }
    return this._mergedApplications;
  }

  public set mergedApplications(value) {
    this._mergedApplications = value;
  }

  public static getCurrentData(): AppData {
    return this.currentData;
  }

  public static getMergedData(): AppData {
    return this.mergedData;
  }

  public getLanguages(): Observable<Country[]> {
    this.setHeaders();
    this.countries = [];
    return this.http.get<Country[]>(environment.api + '/countries', this.options)
      .pipe(
        timeout(environment.xhrTimeout),
        map(res => {
          this.mergeCountries(res);
          return this.countries;
        }),
        catchError(err => throwError(err || 'Server error'))
      );
  }

  public getLocalLanguages(): Country[] {
    this.countries = [];
    this.countries = this.storageHelper.getItem(CatalogService.COUNTRIES_KEY) as Country[];

    return this.countries;
  }

  public updateCountriesData(countries: Country[]): void {
    this.storageHelper.saveItem(countries, CatalogService.COUNTRIES_KEY);
  }

  public getSelLocalLanguages(): string[] {
    const selLanguages: string[] = this.storageHelper.getItem(CatalogService.SELLANG_KEY) as string[];

    if (selLanguages === undefined || selLanguages === null) {
      return [];
    }

    return selLanguages;
  }

  public saveSelLanguages(selLanguages: string[]): void {
    this.storageHelper.saveItem(selLanguages, CatalogService.SELLANG_KEY);
  }

  public saveSelectedLanguage(selectedLanguage: any): void {
    if (selectedLanguage === undefined) {
      return;
    }

    if (selectedLanguage === null) {
      this.storageHelper.saveItem(null, CatalogService.CURLANG_KEY);
      return;
    }
    this.storageHelper.saveItem(selectedLanguage, CatalogService.CURLANG_KEY);
  }

  public saveSelectedLanguageLabel(label: string): void {
    if (label === undefined || label === null) {
      return;
    }

    this.defaultInstance = label;

    this.storageHelper.saveItem(label, CatalogService.CURLANGLABEL_KEY);
  }

  public getSelectedLanguage(): string {
    return this.storageHelper.getItem(CatalogService.CURLANG_KEY) as string;
  }

  public getSelectedLanguageLabel(): string {
    return this.storageHelper.getItem(CatalogService.CURLANGLABEL_KEY) as string;
  }

  public fetchCurrentData(): Promise<AppData> {
    const currentLanguage = this.storageHelper.getItem(CatalogService.CURLANG_KEY) as string;

    if (currentLanguage === undefined || currentLanguage === null) {
      return Promise.reject('Current language is not set.');
    }

    return this.storageHelper.getStringDbItem(currentLanguage)
      .then(stringData => {
        if (!stringData) {
          throw new Error('Please synchronize data before this app loading');
        }
        CatalogService.currentData = new AppData(JSON.parse(stringData));
        return CatalogService.currentData;
      });
  }

  public initOffer(id: any, callback: any): void {
    const data: any                = CatalogService.getCurrentData();
    const application: Application = data.applications[id];
    const offerPageIndex: string   = Object.keys(application.pages)
      .find(key => application.pages[+key].type === PageType.Offer);
    const offerPage: Page          = application.pages[offerPageIndex];
    const offer: any               = offerPage.offer;
    const selectedOfferDetail      = offer.defaultDetail;
    const globalView               = !!offer.globalImagePath;
    const title                    = offerPage.title;


    let canAddToCart = false;

    if (offer.defaultDetail !== undefined &&
      offer.defaultDetail.slides.some(slide =>
        slide.documents.length !== 0
      )
    ) {
      canAddToCart = true;
    }

    if (!canAddToCart && offer.details !== undefined &&
      offer.details.some(detail =>
        detail.slides.some(slide =>
          slide.documents.length !== 0
        )
      )
    ) {
      canAddToCart = true;
    }

    callback({
      offer,
      selectedOfferDetail,
      globalView,
      title,
      canAddToCart,
      id: offer.id
    });
  }

  public initService(id: any, callback: any): void {
    const data: any    = CatalogService.getCurrentData();
    const service: any = data.services[id];
    callback({
      service,
      id: service.id
    });
  }

  public initFreeFocus(id: any, callback: any): void {
    const data: any    = CatalogService.getCurrentData();
    const service: any = data.freeFocuses[id];
    callback({
      service,
      id: service.id
    });
  }

  public initSupplyMode(id: any, callback: any): void {
    const data                = CatalogService.getCurrentData();
    const supplyMode          = data.supplyModes[id];
    const offer               = supplyMode.offer;
    const selectedOfferDetail = offer.defaultDetail;
    const globalView          = !!offer.globalImagePath;
    const title               = supplyMode.title;

    let canAddToCart = false;

    if (offer.defaultDetail !== undefined &&
      offer.defaultDetail.slides.some(slide =>
        slide.documents.length !== 0
      )
    ) {
      canAddToCart = true;
    }

    if (!canAddToCart && offer.details !== undefined &&
      offer.details.some(detail =>
        detail.slides.some(slide =>
          slide.documents.length !== 0
        )
      )
    ) {
      canAddToCart = true;
    }

    callback({
      offer,
      selectedOfferDetail,
      globalView,
      title,
      canAddToCart,
      id: offer.id
    });
  }

  public initProduct(id: any, supplies: any, callback: any): void {
    const data      = CatalogService.getCurrentData();
    const product   = data.products[id];
    let supplyModes = supplies;

    if (supplyModes !== undefined && supplyModes !== null) {
      supplyModes = supplyModes.map(i => data.supplyModes[i]);
    }

    const equipmentTitle = this.translate.instant('equipment');

    const equipment = this.sanitizer.bypassSecurityTrustHtml('<p class"blue_gradient">'
      + this.translate.instant('certified_by_al') + '</p>');

    const canAddToCart = product.productPdfs.length > 0 || product.equipmentPdfs.length > 0;

    callback({
      product,
      supplyModes,
      equipmentTitle,
      equipment,
      canAddToCart,
      id: product.id
    });
  }

  public initProcess(id: any, current: boolean, callback: any): void {
    let data: any;

    if (!current) {
      data = CatalogService.getMergedData();
    } else {
      data = CatalogService.getCurrentData();
    }

    const application = data.applications[id];
    application.pages = Object.keys(application.pages).sort().map(key => application.pages[key]);

    callback({
      application,
      id: application.id
    });
  }

  public getSelectedInstance(): any {
    const languages = this.getSelLocalLanguages();
    const langKey   = this.getSelectedLanguage();

    if (langKey === undefined || langKey === null) {
      return null;
    }

    const currentLang = languages
      .find(item => item === langKey);

    if (currentLang === undefined) {
      return null;
    }

    return currentLang;
  }

  public processApplications(): void {
    const htmlDecoratorListStart: any    = '<ul class=\"product_list\"><li>';
    const htmlDecoratorListEnd: any      = '</li></ul>';
    const tmpApplications: Application[] = [];
    const data: AppData                  = JSON.parse(JSON.stringify(CatalogService.getCurrentData()));
    const merge: any                     = {};


    for (const app of Object.keys(data.applications).map<Application>(key => data.applications[key])) {
      if (!app.isHome) {
        continue;
      }

      if (!merge[app.name]) {
        merge[app.name] = {};
      }

      if (!merge[app.name][app.id]) {
        merge[app.name][app.id] = new Set();
      }

      Object.keys(data.markets).forEach(marketKey =>
        data.markets[marketKey].segments.forEach(segment =>
          segment.sectors.forEach(sector =>
            sector.applicationIds.forEach(id => {
              if (id === app.id) {
                merge[app.name][id].add(segment.name);
              }
            })
          )
        )
      );
    }

    for (const appName of Object.keys(merge)) {
      const ids = Object.keys(merge[appName]).map(id => parseInt(id, 10));
      if (ids.length === 0) {
        continue;
      }
      if (ids.length === 1) {
        tmpApplications.push(data.applications[ids[0]]);
        continue;
      }

      const mergedApp    = new Application({Id: ids[0], Name: appName});
      mergedApp.isMerged = true;
      mergedApp.pages    = {
        0: new Page({
          Order: 0,
          Type : PageType.Tiles,
          Title: appName,
          Tiles: []
        })
      };

      let segmentsMerged = [];
      for (const id of ids) {
        merge[appName][id].forEach(segment => segmentsMerged.push({name: segment, id}));
      }

      segmentsMerged = segmentsMerged.sort((a, b) => a.name.localeCompare(b.name));

      segmentsMerged.forEach((_, idx) =>
        mergedApp.pages[0].tiles.tiles.push(new Tile({
          Order        : idx,
          Type         : TileType.Application,
          Text         : htmlDecoratorListStart + segmentsMerged[idx].name + htmlDecoratorListEnd,
          ApplicationId: segmentsMerged[idx].id
        }))
      );

      tmpApplications.push(mergedApp);
    }

    this.mergedApplications   = tmpApplications.sort((a, b) => a.name.localeCompare(b.name));
    data.applications         = this.mergedApplications.reduce((acc, curr) => {
      acc[curr.id] = curr;
      return acc;
    }, {});
    CatalogService.mergedData = data;
  }

  public setCurrentTranslations(data: AppData): void {
    const transformedStrings: any = {};

    const dataStringRessources = data.stringRessources;
    for (const idx of Object.keys(dataStringRessources)) {
      const item                            = dataStringRessources[idx];
      transformedStrings[item.BusinessCode] = item.StringValue;
    }
    this.translate.setTranslation('server', transformedStrings);
    this.translate.use('server');
  }

  private mergeCountries(countries: Country[]): void {
    const existingCountries: Country[] = this.getLocalLanguages();
    let existingCountry: Country;

    for (const country of countries) {
      if (existingCountries !== undefined && existingCountries !== null) {
        existingCountry = existingCountries.find(c => c.Id === country.Id);
      }

      for (const lang of country.Languages) {
        let existingLang: Language;

        if (existingCountry !== undefined && existingCountry !== null) {
          existingLang = existingCountry.Languages.find(l => l.Id === lang.Id);
        }

        lang.CountryId = country.Id;
        lang.IsActive  = false;
        lang.IsOffline = false;

        if (lang.Code === 'Master') {
          lang.Name = 'English (GB)';
          lang.Code = 'en-GB';
        }

        if (existingLang === undefined || existingLang === null) {
          continue;
        }

        lang.IsActive  = existingLang.IsActive;
        lang.IsOffline = existingLang.IsOffline;
      }
    }
    this.updateCountriesData(countries);
    this.countries = countries;
  }

  private setHeaders(): void {
    this.headers = new HttpHeaders(
      {
        'Content-Type': 'application/json',
        Accept        : 'application/json'
      });
    this.options = {headers: this.headers, withCredentials: true};
  }
}
