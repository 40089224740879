import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import {environment} from '../../../environments/environment';

import {StorageHelper} from '../../shared/helpers/storage.helper';
import {ZipHelper} from '../../shared/helpers/zip.helper';
import {ALDocument} from '../../shared/models/document.model';

import {Language} from '../../shared/models/language.model';
import {AnalyticsService} from '../../shared/services/analytics.service';
import {CatalogService} from '../../shared/services/catalog.service';

import {SynchronizeService} from '../../shared/services/synchronize.service';

@Component({
    templateUrl: 'download.component.html',
    styleUrls: ['download.component.css'],
    standalone: false
})

export class DownloadDialogComponent {
  public download                   = false;
  public error                      = false;
  public netError                   = false;
  public upToDate                   = false;
  public storage                    = false;
  public downloadProgress           = 0;
  public itemToRemove: string[]     = [];
  public catalogsToRemove: string[] = [];
  public cancelMode                 = false;
  public totalZipNumber             = 0;
  public currentZipNumber           = 0;
  private readonly assets: ALDocument[];
  private manifest: any[];

  constructor(public  dialogRef: MatDialogRef<DownloadDialogComponent>,
              public  syncService: SynchronizeService,
              public  snackBar: MatSnackBar,
              private zipHelper: ZipHelper,
              private storageHelper: StorageHelper,
              private catalogService: CatalogService,
              private analyticsService: AnalyticsService,
              @Inject(MAT_DIALOG_DATA) data: any) {
    this.assets   = data?.assets;
    this.manifest = data?.manifest;
    this.launchAssetsDownloading();
  }

  public cancel(): void {
    this.dialogRef.close();
  }

  public close(): void {
    this.dialogRef.close(this.upToDate && !this.error && !this.storage);
  }

  public askCancel(): void {
    this.cancelMode = !this.cancelMode;
  }

  private async cleanAllUnusedItems(): Promise<void> {
    for (const catalog of this.catalogsToRemove) {
      await this.storageHelper.removeDbBlobItem(catalog);
      await this.storageHelper.removeItem(catalog);
    }

    for (const item of this.itemToRemove) {
      await this.storageHelper.removeDbBlobItem(item);
    }
  }

  private launchAssetsDownloading(): void {
    this.itemToRemove     = [];
    this.catalogsToRemove = [];

    for (const country of this.catalogService.countries) {
      for (const lang of country.Languages) {
        const obj: any  = {};
        obj[country.Id] = lang.Id;

        if (!lang.IsActive) {
          this.catalogsToRemove.push(Language.GetUniqueKey(lang));
        }
      }
    }

    const originalAssets = [];
    for (const asset of this.assets) {
      const document = new ALDocument({});
      document.copyInto(asset);
      originalAssets.push(document);
    }

    Promise.all([
      this.storageHelper.getStringDbItem(SynchronizeService.ASSETS_KEY),
      this.storageHelper.listFiles()
    ])
      .then(([localAssets, files]) => {
        let localDocument: ALDocument[] = [];
        let localAssetsParsed: any;

        if (localAssets === undefined || localAssets === null) {
          localAssetsParsed = [];
        } else {
          localAssetsParsed = JSON.parse(localAssets);
        }

        for (const asset of localAssetsParsed) {
          const document = new ALDocument({});
          document.copyInto(asset);
          localDocument.push(document);
        }

        localDocument                    = localDocument.filter(
          local => files.some(file => file === StorageHelper.normalizeKey(local.path)
          )
        );
        const neededAssets: ALDocument[] = this.cleanAssets(localDocument, this.assets);

        this.launchSyncProcess(originalAssets, neededAssets);
      })
      .catch(err => {
        this.launchSyncProcess(originalAssets, originalAssets);
        console.error(err);
      });
  }

  private launchSyncProcess(originalAssets: ALDocument[], neededAssets: any[]): void {
    this.download = true;
    this.downloadAssets(neededAssets)
      .then(() => this.cleanAllUnusedItems())
      .then(() => this.syncService.endSyncProcess(originalAssets, this.manifest))
      .then(() => {
        this.download = false;
        this.upToDate = true;
      })
      .catch(console.error);
  }

  private downloadAssets(neededAssets: ALDocument[]): Promise<void> {
    const assetsIds = neededAssets.map((a) => a.id);

    const splitArray: ALDocument[][] = assetsIds.reduce((all, one, i) => {
      const chunk = Math.floor(i / environment.zipSize);
      all[chunk]  = [...(all[chunk] || []), one];
      return all;
    }, []);

    this.totalZipNumber   = splitArray.length;
    this.currentZipNumber = 0;
    return this.downloadAsset(splitArray)
      .then(() => {
        this.download = false;
      })
      .catch(err => {
        console.error(err);
        throw err;
      });
  }

  private downloadAsset([chunk, ...rest]: ALDocument[][]): Promise<void> {
    this.currentZipNumber += 1;
    this.downloadProgress = 0;
    return chunk ?
      this.syncService.getAppAssets(chunk, value => this.downloadProgress = value)
        .then(zipFile => {
          return new Promise((resolve, reject) => {
            const reader = (() => {  // https://github.com/ionic-team/capacitor/issues/1564
              const fileReader           = new FileReader();
              // eslint-disable-next-line no-underscore-dangle
              const zoneOriginalInstance = (fileReader as any).__zone_symbol__originalInstance;
              return zoneOriginalInstance || fileReader;
            })();

            reader.onerror = () => {
              reject(new Error('Error reading blob for md5'));
            };
            reader.onload  = (event) => {
              const arrayBuffer = event.target.result;
              resolve(this.zipHelper.loadZipFiles(arrayBuffer));
            };
            reader.readAsArrayBuffer(zipFile.blob);
          });
        })
        .then(() => this.downloadAsset(rest)) :
      Promise.resolve();
  }


  private cleanAssets(localAssets: ALDocument[], currentAssets: ALDocument[]): ALDocument[] {
    if (localAssets === undefined || localAssets === null) {
      return currentAssets;
    }

    for (const asset of localAssets) {
      const lastDate = new Date(asset.lastModified);
      const id       = asset.id;

      const res = currentAssets.find(
        item => item.id === asset.id);

      if (res === undefined) {
        this.itemToRemove.push(asset.path);
      }

      const idx = this.getItemByIdAndDate(lastDate, id.toString(), currentAssets);

      if (idx !== -1) {
        currentAssets.splice(idx, 1);
      }
    }

    return currentAssets;
  }

  private getItemByIdAndDate(lastDate: Date, id: string, currentAssets: ALDocument[]): number {
    const item = currentAssets.find(itm => itm.id === id);

    if (item === undefined) {
      return -1;
    }

    const itemDate = new Date(item.lastModified);

    if (itemDate.getTime() !== lastDate.getTime()) {
      return -1;
    }

    return currentAssets.indexOf(item);
  }
}
