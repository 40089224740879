<div class="component_content" *ngIf="applications">
  <div class="ui-g">
    <div class="ui-g-4">
      <div class="card-header">
        <div *ngIf="!isSearch">
          <h3>{{'list_header_applications' | translate}}</h3>
        </div>
        <span class="middle" *ngIf="!isSearch"></span>
        <div *ngIf="!isSearch">
          <h3>{{(applications | andFilter:{name: filterProcess}).length}}</h3>
        </div>
        <mat-form-field
          class="search-process"
          *ngIf="isSearch">
          <label for="focus-detail"></label>
          <mat-label>{{'SEARCH' | translate}}</mat-label>
          <input
            id="focus-detail"
            matInput
            [formControl]="filterInput">
        </mat-form-field>
        <button
          mat-icon-button
          class="search_button"
          (click)="activeSearch()">
          <mat-icon>search</mat-icon>
        </button>
      </div>
      <div class="card-container">
        <div
          #applicationElm
          *ngFor="let application of applications | andFilter:{name: filterProcess}"
          (click)="openApplication(application)"
          class="application"
          [ngClass]="{'active': selectedApplication?.id === application.id}">
          <mat-card>
            <mat-card-content>
              <span>{{application.name}}</span>
              <img src="./assets/icons/ic_arrow.png" alt="go"/>
            </mat-card-content>
          </mat-card>
        </div>
      </div>
    </div>
    <div class="ui-g-8">
      <app-process [application]="selectedApplication"></app-process>
    </div>
  </div>
</div>
