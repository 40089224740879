<mat-card class="login_container">
  <div class="login_top_container">
    <img class="login_top_image" src="./assets/al-logo.svg" alt="al_logo"/>
  </div>
  <form (ngSubmit)="login()">
    <div class="login_button_container">
      <button
        id="googleBtn"
        class="login_google_button"
        type="button"
        mat-raised-button
        (click)="grantOffline()">
        <img src="./assets/icons/g_logo.png" alt="google_logo"/>
        <h2>{{'GOOGLE_CONNECT' | translate }}</h2>
      </button>
      <button class="other_login" mat-raised-button (click)="login()" type="button">
        {{ 'sing_in' | translate }}
      </button>
    </div>
  </form>
  <br />
  <div>
    <a href="javascript:void(0)" (click)="applyNewAccount()" >{{ 'APPLY_NEW_ACCOUNT' | translate }}</a>
  </div>
  <p class="login_app_version">{{ 'VERSION' | translate }} : {{ appVersion }}</p>
</mat-card>

