import {Component} from '@angular/core';
import {OnInit} from '@angular/core';
import {Input} from '@angular/core';
import {Router} from '@angular/router';
import {DomSanitizer} from '@angular/platform-browser';
import {SafeHtml} from '@angular/platform-browser';

/*SERVICES*/
import {CatalogService} from '../../shared/services/catalog.service';

/*HELPERS*/
import {ThemeHelper} from '../../shared/helpers/theme.helper';
import {NavigationHelper} from '../../shared/helpers/navigation.helper';

/*COMPONENTS*/
import {AppComponent} from '../../app.component';

@Component({
    selector: 'app-tile-product',
    templateUrl: 'tile-product.component.html',
    styleUrls: ['tile-product.component.css'],
    standalone: false
})

export class TileProductComponent implements OnInit {
  @Input() public data: any;
  @Input() public background: string;

  public content: SafeHtml;

  constructor(public  sanitizer: DomSanitizer,
              public  parent: AppComponent,
              private catalogService: CatalogService,
              private router: Router,
              private navigationHelper: NavigationHelper) {
  }

  public ngOnInit(): void {
    this.content = this.sanitizer.bypassSecurityTrustHtml(this.data.text);
  }

  public open(): void {
    this.catalogService.initProduct(this.data.id, this.data.supplyModes,
      (processedData) => {
        this.navigationHelper.routerData = processedData;

        if (this.router.url.indexOf('infography') !== -1) {
          this.parent.openDetail(0, processedData, true);
          return;
        }

        this.parent.openDetail(0, processedData);
      });
  }
}
