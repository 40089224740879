import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';

/*MODELS*/

@Component({
    templateUrl: 'remove-customer.component.html',
    styleUrls: ['remove-customer.component.css'],
    standalone: false
})

export class RemoveCustomerComponent {
  private readonly ids: string[] = [];

  constructor(public dialogRef: MatDialogRef<RemoveCustomerComponent>,
              public snackBar: MatSnackBar,
              @Inject(MAT_DIALOG_DATA) public data: any) {
    if (data !== null) {
      this.ids = JSON.parse(JSON.stringify(data));
    }
  }

  public save(): void {
    this.dialogRef.close(this.ids);
  }
}
