import {Component, Input} from '@angular/core';
import {MatSnackBar} from '@angular/material/snack-bar';
import {TranslateService} from '@ngx-translate/core';

/*COMPONENTS*/
import {AnalyticsType} from '../../shared/enums/analytics.enum';
import {MediaHelper} from '../../shared/helpers/media.helper';

/*HELPERS*/
import {MimeHelper} from '../../shared/helpers/mime.helper';
import {StorageHelper} from '../../shared/helpers/storage.helper';
import {Customer} from '../../shared/models/customer.model';

/*MODELS*/
import {MediaCategory} from '../../shared/models/media-category.model';
import {Media} from '../../shared/models/media.model';
import {AnalyticsService} from '../../shared/services/analytics.service';

/*SERVICES*/
import {CustomerService} from '../../shared/services/customer.service';

@Component({
    selector: 'app-media-detail',
    templateUrl: 'media-detail.component.html',
    styleUrls: ['media-detail.component.css'],
    standalone: false
})

export class MediaDetailComponent {
  @Input() mediasTitle: string;
  @Input() mediaCategory: MediaCategory;
  @Input() filterString: string;
  @Input() isSearchView = true;

  public loadingRef: any              = null;
  public selMode                      = false;
  private readonly customer: Customer = new Customer();
  private ids: string[]               = [];

  constructor(public mimeHelper: MimeHelper,
              private snackBar: MatSnackBar,
              private storageHelper: StorageHelper,
              private translate: TranslateService,
              private customerService: CustomerService,
              private mediaHelper: MediaHelper,
              private analyticsService: AnalyticsService) {
    // this.bodyComp.setDataSource(this);
    this.customer = this.customerService.getSelectedCustomer();
  }

  public selectMedia(media: Media): void {
    if (this.selMode) {
      return;
    }

    this.analyticsService.sendEvent(AnalyticsType.OPEN_DOCUMENT_FROM_MEDIA, media.Code);

    this.mediaHelper.openMedia(media.Code);
  }

  public onSelMedia(event: any, media: Media): void {
    if (event.checked) {
      this.customer.Medias.push(media);
      this.ids.push(media.Id);
      return;
    }
    this.customer.Medias = this.customer.Medias.filter(m => m.Id !== media.Id);

    const idx: number = this.ids.indexOf(media.Id);

    if (idx === -1) {
      return;
    }

    this.ids.splice(idx, 1);
  }

  public isSelected(media: Media): boolean {
    const foundedItem = this.customer.Medias
      .find((item) => item.Code === media.Code);
    return foundedItem !== undefined;
  }

  public setSelMode(): void {
    if (this.customer === null || this.customer === undefined) {
      this.snackBar.open(this.translate.instant('PLS_SEL_BEFORE'), 'x',
        {
          duration: 5000
        });
      return;
    }
    this.selMode = !this.selMode;
  }

  public saveCustomer(): void {
    this.customerService.setSelectedCustomer(this.customer);

    if (this.ids.length > 0) {
      this.snackBar.open(this.translate.instant('added_to_cart'), 'x',
        {
          duration: 5000
        });
      this.ids = [];
    }
    this.selMode = !this.selMode;
  }
}
